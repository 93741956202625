
  import { Options, Vue } from "vue-class-component"

  @Options({
    name: "AuthChallengeDirect",
    props: {
      handler: Object,
      state: String,
    },
    data() {
      return {
        password: "",
      }
    },
    mounted() {
      this.setFocus()
    },
    methods: {
      submitPassword() {
        this.$emit("submitInput", this.password)
      },
      setFocus() {
        this.$nextTick(() => {
          if (this.$refs.creds) {
            this.$refs.creds.focus()
            this.$refs.creds.select()
          }
        })
      },
    },
  })
  export default class AuthChallengeDirect extends Vue {}
