
  import { Options, Vue } from "vue-class-component"
  import { RestExc } from "@lokavaluto/lokapi-browser"
  import { e as RequestExc } from "@0k/types-request"

  @Options({
    name: "ResetPassword",
    data() {
      return {
        email: "",
        fail: "",
        success: "",
      }
    },
    methods: {
      async submit(): Promise<void> {
        this.$loading.show()
        try {
          await this.$lokapi.resetPassword(this.email)
        } catch (e) {
          if (e instanceof RestExc.InvalidUserOrEmail)
            this.fail = this.$gettext(
              "Unknown user or email. Please make sure to provide a valid username or email."
            )
          else
            this.fail = this.$gettext(
              "Unexpected issue when attempting to connect to remote server."
            )
          throw e
        } finally {
          this.$loading.hide()
        }
        this.success = this.$gettext("Email sent")
        this.$router.push({ name: "dashboard" })
      },
    },
  })
  export default class ResetPassword extends Vue {}
