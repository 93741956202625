
  import { mapGetters } from "vuex"
  import { Options, Vue } from "vue-class-component"
  import { mapModuleState } from "@/utils/vuex"

  @Options({
    name: "BankAccountItem",
    props: {
      name: String,
      bal: Number,
      curr: String,
      backend: String,
      type: String,
      active: Boolean,
      subAccounts: Array,
      isSub: Boolean,
    },
    computed: {
      ...mapModuleState("lokapi", ["isMultiCurrency"]),
      ...mapGetters(["numericFormat"]),
    },
  })
  export default class BankAccountItem extends Vue {}
