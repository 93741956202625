
  import { Options, Vue } from "vue-class-component"

  @Options({
    displayName: "Code Pin",
    name: "AuthPrefPin",
    props: {
      handler: Object,
    },
    data() {
      return {
        editMode: true,
        pinLength: 4,
        pin: "",
        pinConf: "",
        showConfPin: false,
        isConfigured: false,
        errors: {
          pinSize: false,
          pinConfirmation: false,
        },
      }
    },
    created() {
      if (this.handler.userConfig) {
        this.isConfigured = true
        this.editMode = false
      } else {
        this.setFocusPin()
      }
    },
    methods: {
      onlyNumbers($event: any) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          $event.preventDefault()
        }
      },
      showConfirmPin() {
        if (this.pin.length == this.pinLength) this.showConfPin = true
      },
      isPinValid() {
        if (this.pinConf.length !== this.pinLength) {
          return
        }

        if (this.pin !== this.pinConf) {
          this.errors.pinConfirmation = this.$gettext(
            "Given PINs are different"
          )
        } else {
          this.errors.pinConfirmation = false
        }
        if (this.pin.length < this.pinLength) {
          const msgNbDigits = this.$ngettext("digit", "digits", this.pinLength)
          this.errors.pinSize = this.$gettext(
            "PIN code must contain %{ pinLength }",
            {
              pinLength: this.$ngettext("digit", "digits", this.pinLength),
            }
          )
        } else {
          this.errors.pinSize = false
        }

        if (!this.errors.pinSize && !this.errors.pinConfirmation) {
          this.setFocusSubmit()
          return true
        } else {
          return false
        }
      },
      submitConfig() {
        this.$emit("submitConfig", this.handler.toUserConfigJson(this.pin))
        this.editMode = false
        this.isConfigured = true
      },
      setFocusPin() {
        this.$nextTick(() => {
          if (this.$refs.pin) {
            this.$refs.pin.focus()
            this.$refs.pin.select()
          }
        })
      },
      setFocusSubmit() {
        this.$nextTick(() => {
          if (this.$refs.submit) {
            this.$refs.submit.focus()
          }
        })
      },
    },
  })
  export default class AuthPrefPin extends Vue {}
