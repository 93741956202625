
  import { Options, Vue } from "vue-class-component"

  @Options({
    name: "TheDashboardFooter",
    data: () => {
      modalName: ""
    },
    components: {},
    computed: {
      hasActiveMoneyAccount(): boolean {
        return this.$store.getters.activeVirtualAccounts.length > 0
      },
    },
  })
  export default class TheDashboardFooter extends Vue {}
