
  import { Options, Vue } from "vue-class-component"

  import AuthPrefPin from "@/components/AuthPrefPin.vue"
  import AuthPrefDirect from "@/components/AuthPrefDirect.vue"

  @Options({
    name: "AuthPrefRetention",
    components: {
      AuthPrefPin,
      AuthPrefDirect,
    },
    props: {
      handler: Object,
    },
    methods: {
      submitConfig(subUserConfig: string) {
        const userConfig = {
          subConfig: subUserConfig,
        }
        this.$emit("submitConfig", userConfig)
      },
    },
  })
  export default class AuthPrefRetention extends Vue {}
