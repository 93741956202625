
  import { Options, Vue } from "vue-class-component"
  import Loading from "vue-loading-overlay"

  @Options({
    name: "PendingAccounts",
    components: { Loading: Loading },
    data() {
      return {
        isLoading: false,
        hasLoadingError: false,
        isWaitingForValidation: false,
        selectedItem: null,
      }
    },
    mounted() {
      this.updatePendingAccount()
    },
    computed: {
      pendingUserAccounts(): Array<any> {
        return this.$store.state.lokapi.pendingUserAccounts
      },
    },
    methods: {
      async validateUserAccount(account: any): Promise<void> {
        this.selectedItem = account
        this.isWaitingForValidation = true
        try {
          await account.validateCreation()
        } catch (err: any) {
          this.isWaitingForValidation = false
          if (err.message === "User canceled the dialog box") {
            // A warning message should have already been sent
            return
          }

          this.$msg.error(
            this.$gettext(
              "An unexpected issue occurred while approving " +
                "the wallet account creation of user %{ name }",
              {
                name: account.name,
              }
            )
          )
          throw err
        }
        this.isWaitingForValidation = false
        await this.updatePendingAccount()
        this.$msg.success(
          this.$gettext("User %{ name }'s account creation was approved", {
            name: account.name,
          })
        )
      },
      async updatePendingAccount() {
        this.isLoading = true
        try {
          await this.$store.dispatch("fetchPendingUserAccounts")
          this.hasLoadingError = false
        } catch (e: any) {
          console.error("Failed to fetch pending accounts", e)
          this.hasLoadingError = true
        }
        this.isLoading = false
      },
    },
  })
  export default class Admin extends Vue {}
