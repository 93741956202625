
  import { Options, Vue } from "vue-class-component"
  import { mapModuleState } from "@/utils/vuex"
  import { mapGetters } from "vuex"

  @Options({
    name: "TheNavBar",
    components: {},
    data() {
      return {
        showNav: false,
      }
    },
    methods: {
      logout() {
        this.$store.dispatch("askLogOut")
        this.$router.push({ name: "Login" })
        this.$auth.flush()
      },
    },
    computed: {
      hasPreferences(): boolean {
        return this.$store.state.prefs.componentDefs.length > 0
      },
      profilePageUrl(): string {
        return this.$store.getters.getOdooUrl() + "/web/login"
      },
      hasMapUrl(): string {
        return this.$config.mapUrl
      },
      helpUrl(): string {
        return this.$config.helpUrl
      },
      cguUrl(): string {
        return this.$config.cguUrl
      },

      ...mapModuleState("lokapi", [
        "isLog",
        "hasUserAccountValidationRights",
        "hasCreditRequestValidationRights",
        "userProfile",
      ]),
      ...mapGetters(["hasUnconfiguredBackend"]),
    },
  })
  export default class TheNavBar extends Vue {}
