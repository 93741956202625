
  import { mapGetters } from "vuex"

  import { Options, Vue } from "vue-class-component"
  import Loading from "vue-loading-overlay"
  import DatePicker from "vue-datepicker-next"

  import { ModelListSelect } from "vue-search-select"
  import { Capacitor } from "@capacitor/core"
  import moment from "moment"

  import TransactionListRecent from "./TransactionListRecent.vue"
  import TransactionItem from "./TransactionItem.vue"

  // Assets

  import "vue-datepicker-next/index.css"
  import "vue-search-select/dist/VueSearchSelect.css"
  import "@/assets/datepicker.scss"

  import { mapModuleState } from "@/utils/vuex"

  @Options({
    name: "TransactionListModal",
    components: {
      Loading,
      DatePicker,
      TransactionItem,
      ModelListSelect,
    },

    data(this: any) {
      return {
        selectExportLoader: null,
        exportDate: ["", ""],
        datePickerShow: false,
        selectorLabels: {
          day: this.$gettext("day"),
          week: this.$gettext("week"),
          month: this.$gettext("month"),
          year: this.$gettext("year"),
        },
        selectorsOrder: ["day", "week", "month", "year"],
        selectedTimeSpanType: "",
        selectedTimeSpanOffset: 0,
        transactions: [],
        isTransactionsBatchLoading: false,
        isTransactionsLoading: false,
        recipientList: [],
        recipientsSearchString: "",
        selectedRecipient: {},
      }
    },
    async mounted() {
      this.resetTransactionsGen()
      this.recipientList = await this.searchRecipients("")
    },
    computed: {
      getPlatform(): string {
        return Capacitor.getPlatform()
      },
      isSelectionCurrent(): boolean {
        return moment().isBetween(this.exportDate[0], this.exportDate[1])
      },
      selectedTimeSpan() {
        const now = moment().toDate()
        const timeSpanType = this.selectedTimeSpanType
        const offset = this.selectedTimeSpanOffset
        const dateSelected = moment(now)
          .subtract(-offset, timeSpanType)
          .toDate()
        const [begin, end] = [
          moment(dateSelected).startOf(timeSpanType),
          moment(dateSelected).endOf(timeSpanType),
        ].map((m) => m.toDate())

        return [begin, now < end ? now : end]
      },
      ...mapModuleState("lokapi", [
        "transactionsLoading",
        "lastTransactions",
        "userProfile",
      ]),
      ...mapGetters(["numericFormat", "dateFormat"]),
    },
    methods: {
      async createCsvFile() {
        const transactions = []
        const [dateBegin, dateEnd] = this.exportDate

        this.isTransactionsLoading = true

        try {
          for await (const t of this.getTransactions()) {
            transactions.push(t)
          }
        } catch (e) {
          this.$msg.error(
            this.$gettext(
              "An unexpected issue occured while downloading transaction list"
            )
          )
          throw e
        } finally {
          this.isTransactionsLoading = false
        }

        let exportFileName
        if (dateBegin && dateEnd) {
          let dateBeginStr = moment(dateBegin).format("YYYY-MM-DD")
          let dateEndStr = moment(dateEnd).format("YYYY-MM-DD")
          exportFileName = `transactions_${dateBeginStr}_${dateEndStr}.csv`
        } else {
          exportFileName = "transactions.csv"
        }

        const columnOrder = [
          "sender",
          "receiver",
          "amount",
          "date",
          "description",
        ]
        let csvDataLine: { [key: string]: string }[] = [
          {
            sender: this.$gettext("Source"),
            receiver: this.$gettext("Target"),
            amount: this.$gettext("Amount"),
            date: this.$gettext("Date"),
            description: this.$gettext("Description"),
          },
        ]

        for (let e of transactions) {
          let name = e.related
          let [sender, receiver] = e.amount.startsWith("-")
            ? [this.userProfile.name, name]
            : [name, this.userProfile.name]
          let data: { [key: string]: string } = {
            sender,
            receiver,
            amount: this.numericFormat(e.amount),
            date: moment(e.date).format("YYYY-MM-DD HH:mm:ss"),
            description: e.description || "",
          }

          for (const s of columnOrder) {
            data[s] = '"' + data[s].replaceAll('"', '""') + '"'
          }

          csvDataLine.push(data)
        }

        return {
          csvContent:
            csvDataLine
              .map((dataLine) =>
                columnOrder.map((header) => dataLine[header]).join(",")
              )
              .join("\r\n") + "\r\n",
          exportFileName,
        }
      },
      async downloadCsvFile() {
        this.selectExportLoader = 1
        const { csvContent, exportFileName } = await this.createCsvFile()
        try {
          await this.$export.download(csvContent, exportFileName, "text/csv")
        } catch (e) {
          this.$msg.error(
            this.$gettext("Transaction list could not be downloaded")
          )
          throw e
        }
        this.$modal.close()
        this.$msg.success(this.$gettext("Transaction list downloaded"))
      },
      async shareCsvFile() {
        this.selectExportLoader = 2
        const { csvContent, exportFileName } = await this.createCsvFile()
        let dateBeginStr, dateEndStr
        if (this.exportDate[0] && this.exportDate[1]) {
          dateBeginStr = moment(this.exportDate[0]).format("YYYY-MM-DD")
          dateEndStr = moment(this.exportDate[1]).format("YYYY-MM-DD")
        } else {
          dateBeginStr = ""
          dateEndStr = ""
        }
        try {
          await this.$export.share(csvContent, exportFileName, [
            dateBeginStr,
            dateEndStr,
          ])
        } catch (e) {
          this.$msg.error(
            this.$gettext("Transaction list could not be downloaded")
          )
          throw e
        }
        this.$modal.close()
        this.$msg.success(this.$gettext("Transaction list shared"))
      },
      disabledDates(date: Date) {
        return date > moment().endOf("day").toDate()
      },
      async getNextFilteredTransactions() {
        if (!this.transactionGen) return

        let div = this.$refs.transactionsContainer
        while (div.scrollHeight - (div.scrollTop + div.offsetHeight) <= 500) {
          let next
          this.isTransactionsBatchLoading = true
          let currentGen = this.transactionGen
          try {
            next = await this.transactionGen.next()
          } catch (e) {
            if (currentGen !== this.transactionGen) {
              console.warn("Ignored exception from obsolete transaction", e)
              break
            }
            this.isTransactionsBatchLoading = false
            this.$msg.error(
              this.$gettext(
                "An unexpected issue occured while downloading transaction list"
              )
            )
            throw e
          }
          if (currentGen !== this.transactionGen) {
            console.log("Canceled obsolete transaction request.")
            break
          }
          this.isTransactionsBatchLoading = false
          if (next.done) {
            this.transactionGen = null
            break
          }

          this.transactions.push(<any>next.value)
        }
      },
      resetTransactionsGen() {
        this.transactionGen = this.getTransactions()
        this.transactions = []
        this.$nextTick(() => this.getNextFilteredTransactions())
      },
      async *getTransactions() {
        const gen = this.$lokapi.getTransactions()

        const [dateBegin, dateEnd] = this.exportDate
        const selectedRecipientName = this.selectedRecipient?.name

        for await (const t of gen) {
          if (selectedRecipientName && selectedRecipientName !== t.related)
            continue
          if (dateBegin && t.date < dateBegin) break
          if (dateEnd && t.date > dateEnd) continue
          yield t
        }
      },
      async searchRecipients(recipientsSearchString: string): Promise<any[]> {
        let recipients = null
        try {
          recipients = await this.$lokapi.searchRecipients(
            recipientsSearchString
          )
        } catch (err: any) {
          console.log("searchRecipients() Failed", err)
          return []
        }
        return [...new Set(recipients.map((r: any) => r.name))]
      },
      async onSearch(recipientsSearchString: any) {
        if (recipientsSearchString.length > 2)
          this.recipientList = await this.searchRecipients(
            recipientsSearchString
          )
      },
      async resetRecipientSearch(event: any) {
        this.selectedRecipient = {}
        this.recipientList = await this.searchRecipients("")
      },
    },
    watch: {
      selectedRecipient: async function (newRecipient): Promise<void> {
        this.resetTransactionsGen()
      },
      exportDate: async function (newExportDate): Promise<void> {
        let [newBegin, newEnd] = newExportDate
        const [normBegin, normEnd] = [
          newBegin ? moment(newBegin).startOf("day").toDate() : null,
          newEnd ? moment(newEnd).endOf("day").toDate() : null,
        ]
        if (
          normBegin &&
          normEnd &&
          (+newBegin != +normBegin || +newEnd != +normEnd)
        ) {
          this.exportDate = [normBegin, normEnd]
          return
        }
        this.resetTransactionsGen()
      },
    },
  })
  export default class TheTransactionList extends Vue {}
