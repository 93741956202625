import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9df8414"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "columns is-tablet" }
const _hoisted_4 = { class: "column is-half" }
const _hoisted_5 = { class: "column is-half" }
const _hoisted_6 = { class: "action-footer-container" }
const _hoisted_7 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheBankAccountList = _resolveComponent("TheBankAccountList")!
  const _component_TheTransactionList = _resolveComponent("TheTransactionList")!
  const _component_TheDashboardFooter = _resolveComponent("TheDashboardFooter")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_TheBankAccountList)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_TheTransactionList)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_TheDashboardFooter)
        ])
      ])
    ])
  ]))
}