
  import { Options, Vue } from "vue-class-component"
  import TransactionItem from "./TransactionItem.vue"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/css/index.css"
  import { mapModuleState } from "@/utils/vuex"

  let interval: any

  @Options({
    name: "TransactionListRecent",
    components: {
      TransactionItem,
      Loading,
    },
    mounted() {
      const transactionsRefreshInterval =
        this.$config.transactionsRefreshInterval || 90

      if (transactionsRefreshInterval != -1) {
        if (interval) clearInterval(interval)

        interval = setInterval(() => {
          this.$store.dispatch("resetTransactions")
        }, Math.max(10000, transactionsRefreshInterval * 1000))
      }
    },
    unmounted() {
      if (interval) {
        clearInterval(interval)
        interval = null
      }
    },
    computed: {
      ...mapModuleState("lokapi", [
        "lastTransactions",
        "transactionsLoading",
        "transactionsLoadingError",
      ]),
    },

    methods: {},
  })
  export default class TransactionListRecent extends Vue {}
