
  import { Options, Vue } from "vue-class-component"
  import AuthChallengeRetention from "@/components/AuthChallengeRetention.vue"
  import AuthChallengeDirect from "@/components/AuthChallengeDirect.vue"

  @Options({
    name: "AuthChallenge",
    components: {
      AuthChallengeDirect,
      AuthChallengeRetention,
    },
    data() {
      return {
        visible: false,
        state: null,
        handler: {},
        callbacks: {},
      }
    },

    created() {
      this.$auth.registerRequestCredentials(
        (state: string, handler: any) =>
          new Promise((resolve, reject) => {
            this.callbacks = { resolve, reject }
            this.show(handler, state)
          })
      )
    },
    methods: {
      submitInput(credsInput: string) {
        this.hide()
        this.callbacks.resolve(credsInput)
      },

      cancelInput(error?: any) {
        this.hide()
        this.$msg.warning(this.$gettext("Operation canceled"))
        this.callbacks.reject(
          error || new Error("User canceled the dialog box")
        )
      },

      hide() {
        this.visible = false
        // Destroys in memory previous challenge structure
        this.handler = false
      },

      show(handler: any, state: string) {
        this.visible = true
        this.handler = handler
        this.state = state
      },
    },
  })
  export default class AuthChallenge extends Vue {}
