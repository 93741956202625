
  import { mapState } from "vuex"
  import { Options, Vue } from "vue-class-component"
  import AuthPrefs from "@/components/AuthPrefs.vue"
  import LangPrefs from "@/components/LangPrefs.vue"
  import BiometryPrefs from "@/components/BiometryPrefs.vue"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/css/index.css"

  @Options({
    name: "Prefs",
    components: { AuthPrefs, LangPrefs, Loading, BiometryPrefs },
    computed: {
      ...mapState({
        componentDefs: (state: any) => state.prefs.componentDefs,
      }),
    },
  })
  export default class Prefs extends Vue {}
