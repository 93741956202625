
  import { markRaw } from "vue"
  import { Options, Vue } from "vue-class-component"
  import { LokAPIExc } from "@/services/lokapiService"
  import AuthPref from "@/components/AuthPref.vue"
  import { mapGetters } from "vuex"
  @Options({
    name: "CreateAccount",
    components: { AuthPref },
    async created() {
      const accountAuth = await this.$auth.getAccountAuth("new")
      this.handler = markRaw(accountAuth.authPrefHandler)
    },
    mounted() {
      const unconfiguredBackends = this.getUnconfiguredBackends()
      if (unconfiguredBackends.length === 0) {
        this.$router.push("/")
        return
      }
      this.form.accountBackend = unconfiguredBackends[0]
    },
    data() {
      return {
        handler: false,
        useSimplifiedAuth: false,
        form: {
          accountBackend: "",
          accountPassword: "",
          accountPasswordConfirm: "",
          errors: {
            accountPassword: [],
            accountPasswordConfirm: [],
          },
        },
      }
    },
    watch: {
      getUnconfiguredBackends(newval, oldval): void {
        if (newval.length === 1) {
          this.form.accountBackend = newval[0]
        }
      },
      "form.accountPassword": function (): void {
        this.checkPasswordField("accountPassword")
        this.checkIsSame("accountPasswordConfirm", "accountPassword")
      },
      "form.accountPasswordConfirm": function (): void {
        this.checkIsSame("accountPasswordConfirm", "accountPassword")
      },
    },
    computed: {
      hasFieldErrors(): boolean {
        if (
          this.form.accountPassword.length === 0 ||
          this.form.accountPasswordConfirm.length === 0
        )
          return true
        return (
          Object.keys(this.form.errors).filter((field) => {
            return this.form.errors[field].length > 0
          }).length > 0
        )
      },
      requestCredentials() {
        return () => {
          if (this.hasFieldErrors)
            throw Error(
              "This action should not be triggerable when there are still form errors."
            )
          this.useSimplifiedAuth = true
          return this.form.accountPassword
        }
      },

      ...mapGetters(["getUnconfiguredBackends"]),
    },
    methods: {
      async checkPasswordField(fieldname: string, accountBackend: string) {
        this.form.errors[fieldname] = await this.$store.dispatch(
          "checkPasswordStrength",
          [this.form[fieldname], this.form["accountBackend"]]
        )
      },
      checkIsSame(fieldOne: string, fieldTwo: string): void {
        if (this.form[fieldOne] !== this.form[fieldTwo]) {
          this.form.errors[fieldOne] = [this.$gettext("Passwords do not match")]
        } else {
          this.form.errors[fieldOne] = []
        }
      },
      hasError(field: string): boolean {
        return this.form.errors[field].length > 0
      },
      saveSimplifiedAuthPref(accountAuthService: any, userConfigInput: any) {
        this.userAuthPref = [accountAuthService, userConfigInput]
      },
      async createUserAccount() {
        let userAccount
        try {
          userAccount = await this.$store.dispatch("createUserAccount", [
            this.form["accountPassword"],
            this.form["accountBackend"],
          ])
        } catch (err: any) {
          console.error(
            "Something went wrong on createUserAccount request",
            err
          )
          if (!(err instanceof LokAPIExc.UserAccountAlreadyExists)) {
            this.$msg.error(
              this.$gettext("Wallet creation unexpectedly failed.") +
                " " +
                this.$gettext("Please try again or contact your administrator")
            )

            return // stay on page
          }
          this.$msg.warning(this.$gettext("Wallet already created"))
        }

        if (this.userAuthPref) {
          try {
            const [accountAuthService, userConfigInput] = this.userAuthPref
            accountAuthService.configId = userAccount.internalId
            await accountAuthService.setUserConfig(userConfigInput)
          } catch (err: any) {
            console.error(
              "Something went wrong on createUserAccount request",
              err
            )
            this.$msg.error(
              this.$gettext(
                "Settings for simplified authentication were not saved correctly... " +
                  'Please try again in the "Settings" page or contact your administrator'
              )
            )
          }
        }
        this.$store.dispatch("fetchComponentDefs")
        this.$router.push({ name: "dashboard" })
      },
    },
  })
  export default class CreateAccount extends Vue {}
