import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card custom-card custom-card-padding" }
const _hoisted_2 = { class: "is-flex-direction-column is-align-items-center is-justify-content-space-between" }
const _hoisted_3 = {
  key: 0,
  class: "has-text-centered mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionListRecent = _resolveComponent("TransactionListRecent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TransactionListRecent)
    ]),
    (_ctx.lastTransactions?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$modal.open('TransactionListModal'))),
            class: "button custom-button custom-inverted"
          }, _toDisplayString(_ctx.$gettext("See more")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}