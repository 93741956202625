
  import { Options, Vue } from "vue-class-component"

  @Options({
    name: "Dialog",
    components: {},
    data() {
      return {
        visible: false,
        callbacks: {},
        opts: {},
      }
    },

    created() {
      this.$dialog.register(
        (opts: any) =>
          new Promise((resolve, reject) => {
            this.callbacks = { resolve, reject }
            this.show(opts)
          })
      )
    },
    methods: {
      submitInput(label: string) {
        this.hide()
        this.callbacks.resolve(label)
      },

      cancelInput(error?: any) {
        this.hide()
        this.$msg.warning(this.$gettext("Operation canceled"))
        this.callbacks.reject(
          error || new Error("User canceled the dialog box")
        )
      },

      hide() {
        this.visible = false
      },

      show(opts: any) {
        this.opts = opts
        this.visible = true
      },
    },
  })
  export default class Dialog extends Vue {}
