
  import { Options, Vue } from "vue-class-component"
  import { RestExc } from "@lokavaluto/lokapi-browser"
  import PasswordUtilsFactory from "@/utils/password"
  @Options({
    name: "Signup",
    data() {
      return {
        email: "",
        lastName: "",
        firstName: "",
        password: "",
        confirmPassword: "",
        errors: {
          email: "",
          lastName: "",
          firstName: "",
          passwordStrength: "",
          confirmPassword: "",
        },
      }
    },
    computed: {
      isFormValid() {
        return !Object.keys(this.errors).length
      },
    },
    methods: {
      async submit(): Promise<void> {
        this.$loading.show()
        try {
          await this.$lokapi.signup(
            this.email,
            this.lastName,
            this.firstName,
            this.password
          )
        } catch (e) {
          if (e instanceof RestExc.UserOrEmailAlreadyTaken) {
            this.errors.email = this.$gettext("User or email already exist.")
          } else {
            this.errors.default = this.$gettext(
              "Unexpected issue when attempting to connect to remote server."
            )
          }

          throw e
        } finally {
          this.$loading.hide()
        }
        this.$router.push({ name: "Login" })
        this.$msg.success(
          this.$gettext("Your account has been created successfully.")
        )
      },
      checkEmail() {
        if (this.email === "") {
          this.errors.email = this.$gettext("This fiels can not be empty.")
        } else if (
          !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/.test(this.email)
        ) {
          this.errors.email = this.$gettext("Invalid email.")
        } else {
          delete this.errors.email
        }
      },
      checkLastName() {
        if (this.lastName === "") {
          this.errors.lastName = this.$gettext("This fiels can not be empty.")
        } else {
          delete this.errors.lastName
        }
      },
      checkFirstName() {
        if (this.firstName === "") {
          this.errors.firstName = this.$gettext("This fiels can not be empty.")
        } else {
          delete this.errors.firstName
        }
      },
      async checkPasswordStrength() {
        let passwordStrength = this.$passwordUtils.translatePwdFieldErrors(
          await this.$lokapi.isPasswordStrongEnoughSync(this.password)
        )
        if (passwordStrength.length) {
          this.errors.passwordStrength = passwordStrength
        } else {
          delete this.errors.passwordStrength
        }
      },
      checkConfirmPassword() {
        if (this.password !== this.confirmPassword) {
          this.errors.confirmPassword = this.$gettext(
            "The password and the password confirmation must be equal."
          )
        } else {
          delete this.errors.confirmPassword
        }
      },
    },
  })
  export default class Signup extends Vue {}
