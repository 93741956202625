import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a97b38e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  id: "the-transaction-list"
}
const _hoisted_2 = {
  key: 0,
  class: "notification is-danger is-light"
}
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = {
  key: 1,
  class: "notification is-default"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "custom-card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_TransactionItem = _resolveComponent("TransactionItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.lastTransactions.length)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          active: _ctx.transactionsLoading,
          "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.transactionsLoading) = $event)),
          "can-cancel": false,
          "is-full-page": false
        }, null, 8, ["active"]))
      : _createCommentVNode("", true),
    (!_ctx.transactionsLoading || _ctx.lastTransactions.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass([{
        hide: !_ctx.transactionsLoading,
      }, "icon is-small is-default is-pulled-right is-rounded refresh"])
          }, [
            _createVNode(_component_fa_icon, {
              class: _normalizeClass({ refreshing: _ctx.transactionsLoading }),
              icon: "sync"
            }, null, 8, ["class"])
          ], 2),
          (_ctx.transactionsLoadingError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$gettext(
            "An unexpected issue occurred while loading the last " +
              "transactions. Sorry for the inconvenience."
          )), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$gettext(
            "You can try to refresh the page, if the issue persists, " +
              "you may want to contact your administrator"
          )), 1)
              ]))
            : (_ctx.lastTransactions?.length === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$gettext("No previous transactions in your history.")), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$gettext("Transactions")), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastTransactions, (transaction) => {
                    return (_openBlock(), _createBlock(_component_TransactionItem, {
                      key: transaction,
                      transaction: transaction
                    }, null, 8, ["transaction"]))
                  }), 128))
                ]))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}