
  import { Options, Vue } from "vue-class-component"
  @Options({
    name: "BiometryPrefs",
    components: {},
    props: {},
    data() {
      return {
        useBiometry: false,
        hasCredentialsAvailable: false,
      }
    },
    async mounted() {
      this.useBiometry = (await this.$localSettings.load())?.biometryEnabled
      this.hasCredentialsAvailable =
        await this.$biometry.hasCredentialsAvailable("login")
    },
    methods: {
      setUseBiometry(value: any) {
        this.setUserBiometry(value)
        this.useBiometry = value
        // XXXvlab: nextTick didn't work.
        setTimeout(() => {
          this.$refs.biometryCheckbox.checked = value
        }, 0)
      },
      switchUseBiometry(e: any) {
        e.preventDefault()
        this.setUseBiometry(!this.useBiometry)
      },
      async setUserBiometry(l: string) {
        let settings = (await this.$localSettings.load()) || {}
        settings.biometryEnabled = l
        await this.$localSettings.save(settings)
        if (!settings.biometryEnabled) {
          try {
            await this.$biometry.deleteCredentials("login")
          } catch (e) {
            console.error(e)
            throw e
          }
          this.hasCredentialsAvailable = false
          this.$msg.success(
            this.$gettext("Biometric login successfully disabled")
          )
        }
      },
    },
  })
  export default class BiometryPrefs extends Vue {}
