
  import { Options, Vue } from "vue-class-component"

  function replaceWithHtml(str: string, replacements: any) {
    return str.replaceAll(
      /%%([a-zA-Z0-9_-]+)%%/g,
      (match: any, p1: string) =>
        (replacements as { [index: string]: string })[p1]
    )
  }

  @Options({
    name: "AboutModal",
    computed: {
      replacements() {
        return {
          appName: `<strong>${this.$appInfo.appName}</strong>`,
          appVersion: `<strong>${this.$appInfo.appVersion}</strong>`,
          organizationName: `<a href='https://lokavaluto.fr/' target='_blank'
                                    ><strong>Lokavaluto</strong
                                ></a>`,
        }
      },
      versionHtml() {
        return replaceWithHtml(
          this.$gettext("%{ appName } version %{ appVersion }", {
            appName: "%%appName%%",
            appVersion: "%%appVersion%%",
          }),
          this.replacements
        )
      },
      modalHtml() {
        return replaceWithHtml(
          this.$gettext(
            "%{ appName } is designed by %{ organizationName } " +
              "an association whose goal is to develop, implement," +
              " and support the deployment of evolving and open-source" +
              " information systems for organizations promoting local " +
              "economic development.",
            {
              appName: "%%appName%%",
              organizationName: "%%organizationName%%",
            }
          ),
          this.replacements
        )
      },
      modalTitleText() {
        return this.$gettext("About %{ appName }", {
          appName: this.$appInfo.appName,
        })
      },
    },
    methods: {},
  })
  export default class AboutModal extends Vue {}
