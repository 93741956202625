
  import { mapGetters } from "vuex"
  import { Options, Vue } from "vue-class-component"
  import Loading from "vue-loading-overlay"

  @Options({
    name: "PendingCredits",
    components: { Loading: Loading },
    data() {
      return {
        isLoading: true,
        hasLoadingError: false,
        isWaitingForValidation: false,
        selectedItem: null,
        validationRequestOngoing: [],
      }
    },
    mounted() {
      this.updatePendingCreditRequests()
    },
    computed: {
      pendingCreditRequests(): Array<any> {
        return this.$store.state.lokapi.pendingCreditRequests
      },
      ...mapGetters(["numericFormat"]),
    },
    methods: {
      async validateCreditRequest(request: any): Promise<void> {
        if (this.validationRequestOngoing.includes(request)) {
          console.log("Debounced `.validateCreditRequest()` call")
          return
        }
        this.validationRequestOngoing.push(request)
        this.selectedItem = request
        try {
          this.isWaitingForValidation = true
          await request.validate()
        } catch (err: any) {
          if (err.message === "User canceled the dialog box") {
            // A warning message should have already been sent
            return
          }
          this.$msg.error(
            this.$gettext(
              "An issue occured upon the approval of the credit " +
                "request of %{ name }",
              {
                name: request.related,
              }
            )
          )
          throw err
        } finally {
          this.isWaitingForValidation = false
          this.validationRequestOngoing.splice(
            this.validationRequestOngoing.indexOf(request),
            1
          )
        }
        this.selectedItem = null
        this.$store.dispatch("fetchPendingCreditRequests")
        this.$msg.success(
          this.$gettext(
            "Top up request from %{ name } of %{ amount } %{ currency } was validated.",
            {
              name: request.related,
              amount: request.amount,
              currency: request.currency,
            }
          )
        )
        this.isLoading = false
      },
      async updatePendingCreditRequests() {
        this.isLoading = true
        try {
          await this.$store.dispatch("fetchPendingCreditRequests")
          this.hasLoadingError = false
        } catch (e: any) {
          console.error("Failed to fetch pending credit requests", e)
          this.hasLoadingError = true
        }
        this.isLoading = false
      },
    },
  })
  export default class Admin extends Vue {}
