
  import { Options, Vue } from "vue-class-component"

  import moment from "moment"

  @Options({
    name: "RecipientItem",
    props: {
      recipient: Object,
    },
  })
  export default class RecipientItem extends Vue {}
