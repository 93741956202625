
  import { Options, Vue } from "vue-class-component"
  import { useGettext } from "vue3-gettext"
  @Options({
    name: "LangPrefs",
    components: {},
    props: {
      prefsData: Object,
    },
    data() {
      return {
        useCustomLanguage: false,
        userLanguage: null,
      }
    },
    async mounted() {
      this.userLanguage = (await this.$localSettings.load())?.language

      if (this.userLanguage) {
        this.useCustomLanguage = true
      }
      this.userLanguage = this.userLanguage || this.$language.current
    },
    watch: {
      userLanguage: function (): void {
        this.setCustomLanguage(this.userLanguage)
      },
    },
    methods: {
      setUseCustomLanguage(value: any) {
        this.setCustomLanguage(value ? this.userLanguage : null)
        this.useCustomLanguage = value
        // XXXvlab: nextTick didn't work.
        setTimeout(() => {
          this.$refs.langCheckbox.checked = value
        }, 0)
      },
      switchCustomLanguage(e: any) {
        e.preventDefault()
        this.setUseCustomLanguage(!this.useCustomLanguage)
      },
      async setCustomLanguage(l: string) {
        let settings = (await this.$localSettings.load()) || {}
        if (settings.language != l) {
          settings.language = l
          await this.$localSettings.save(settings)
          await this.$store.dispatch("switchLocale", l)
        }
      },
    },
  })
  export default class LangPrefs extends Vue {}
